import React from 'react';
import home1 from "../img/home1.png";
import {About, Description, Image, Hide} from '../style';
import {motion} from "framer-motion";
import {titleAnim, fade, photoAnim} from "../animation"
import Wave from "./Wave";



const AboutSection = () => {
    return(
        <About>
            <Description>
                <div className="title">
                    <Hide>
                        <motion.h2 variants={titleAnim} >We work to make</motion.h2>
                    </Hide>
                    <Hide>
                        <motion.h2 variants={titleAnim}>your <span>dreams</span> come</motion.h2>
                    </Hide>
                    <Hide>
                        <motion.h2 variants={titleAnim}>true.</motion.h2>
                    </Hide>
                    <motion.p variants={fade}>Contact us for any photography or videography ideas that you have. We have opejf soifhweb weofihwf</motion.p>
                    <motion.button variants={fade}>Contact Us</motion.button>
                </div>
            </Description>
            <Image>
                    <motion.img variants={photoAnim} src={home1} alt="guy with a vrh"/>
            </Image>
            <Wave />
        </About>
    )
}

// styled component




export default AboutSection;