import AboutSection from "./components/AboutSection";
import GlobalStyle from "./components/GlobalStyle";
import AboutUs from "./pages/AboutUs";
import Nav from "./components/nav";
import ContactUs from "./pages/ContactUs";
import OurWork from "./pages/OurWork";
import {Switch, Route, useLocation} from "react-router-dom";
import MovieDetail from "./pages/MovieDetail";
import {AnimatePresence} from "framer-motion";
import ScrollTop from "./components/ScrollTop";


function App() {

  const location = useLocation();
  window.onload = function () {
    setTimeout(function(){
      window.scrollTo(0, 0);
    }, 1);
  };
  return (
    <div className="App">
      <GlobalStyle />
      <Nav />
      <AnimatePresence exitBeforeEnter>
      <Switch location={location} key={location.pathname}>
      <Route path="/" exact>
        <AboutUs />
      </Route>
      <Route path="/work" exact>
        <OurWork />
      </Route>
      <Route path="/work/:id">
        <MovieDetail />
      </Route>
      <Route path="/contact">
        <ContactUs />
      </Route>
      </Switch>
      </AnimatePresence>
      <ScrollTop/>
    </div>
  );
}

export default App;
